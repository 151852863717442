<script>
//Importing Line class from the vue-chartjs wrapper
import { Bar } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
  props: {
    labels: Array,
    datasets: Array
  },
  extends: Bar,
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true,
          position: "bottom"
        },
        tooltips: {
          enabled: true,
          mode: "single"
          // callbacks: {
          //   label: function(tooltipItems) {
          //     return "Kelas " + tooltipItems.yLabel;
          //   }
          // }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200
      }
    };
  },
  mounted() {
    const gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    const gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    const gradient3 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    gradient.addColorStop(1, "rgba(135, 218, 252, 1)");
    gradient.addColorStop(0, "rgba(39, 172, 226, 1)");

    gradient2.addColorStop(1, "rgba(255, 173, 202, 1)");
    gradient2.addColorStop(0, "rgba(255, 13, 100, 1)");

    gradient3.addColorStop(1, "rgba(255, 215, 150, 1)");
    gradient3.addColorStop(0, "rgba(255, 161, 10, 1)");

    this.renderChart(
      {
        labels: this.labels || [
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
          "Minggu"
        ],
        datasets: this.datasets || [
          {
            label: "Kelas 1",
            backgroundColor: gradient,
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: [12, 122, 32, 57, 12, 4, 23]
          },
          {
            label: "Kelas 2",
            backgroundColor: gradient2,
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: [34, 25, 68, 12, 25, 2, 80]
          },
          {
            label: "Kelas 3",
            backgroundColor: gradient3,
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: [45, 30, 70, 80, 20, 13, 96]
          }
        ]
      },
      this.options
    );
  }
};
</script>
